<template>
    <div class="home_list">
        <div class="warpper">
            <section class="tit_screen">
                <h1>展会信息列表</h1>
                <div class="ri">
                    <el-date-picker type="month" placeholder="月份" value-format="YYYY-MM" v-model="query.activMonth"
                        @change="search" />
                    <el-input placeholder="关键词搜索" v-model="query.keyword" @keydown.enter="search" />
                    <span class="sort" :class="query.sort === 'desc' ? 'reverse' : ''" @click="sortChange"></span>
                </div>
            </section>
            <section class="list">
                <div class="li" v-for="item in tableData" :key="item.id" @click="onItem(item)">
                    <div class="img_box">
                        <el-image :src="item.coverImgUrl" fit="cover" />
                    </div>
                    <h1>{{ item.title }}</h1>
                    <div class="info">
                        <p>城市：{{ item.province }}
                            <span v-show="item.province !== item.city">
                                {{ item.city }}
                            </span>
                        </p>
                        <p>时间：{{ item.activStartTime }} - {{ item.activEndTime }}</p>
                    </div>
                    <div class="label_row">
                        <span v-if="item.status === 2">未开始</span>
                        <span v-if="item.status === 1" class="ing">进行中</span>
                        <span v-if="item.status === 0">已结束</span>
                    </div>
                </div>
                <el-empty description="暂无展会信息" v-if="tableData.length === 0" />
            </section>
            <section class="page_box">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="query.pageSize"
                    v-model:current-page="query.pageNum" />
            </section>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { stActivGetPageList } from '@/request/api';
import withLoading from '@/utils/loading';

const $router = useRouter();
const $route = useRoute();

const query = reactive({
    activMonth: $route.query.date || '',
    keyword: '',
    sort: 'asc',// 排序 desc:降序 asc：升序
    pageNum: 1,
    pageSize: 10
})
const total = ref(0);
const tableData = ref([]);

// 获取数据
async function getList() {
    const { data } = await withLoading(stActivGetPageList)(query);
    total.value = data.total || 0;
    tableData.value = data.list || [];
}

// 分页
watch(() => query.pageNum, getList);

// 搜索
function search() {
    query.pageNum = 1;
    getList();
}

// 排序改变
function sortChange() {
    query.sort = query.sort === 'desc' ? 'asc' : 'desc';
    getList();
}

// 详情
function onItem(row) {
    $router.push({ path: '/activity/details/' + row.id, query })
}

getList();
</script>

<style lang="scss" scoped>
.home_list {
    --list_top_bottom_padding: 32px;
    padding: var(--list_top_bottom_padding) 56px;
    min-height: calc(100vh - var(--home-header-height));

    .warpper {
        min-height: calc(100vh - var(--home-header-height) - var(--list_top_bottom_padding) - var(--list_top_bottom_padding));
        background-color: #fff;
        border-radius: 8px;

        .tit_screen {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 32px;

            h1 {
                display: block;
                font-size: 20px;
                color: #2E313B;
            }

            .ri {
                display: flex;
                align-items: center;

                .el-input {
                    width: 220px;
                    margin-left: 15px;
                }

                .sort {
                    width: 24px;
                    height: 24px;
                    background: url(../../../../assets/img/sort.png) center no-repeat;
                    background-size: 100%;
                    margin-left: 24px;
                    cursor: pointer;
                    transition: transform .3s;

                    &.reverse {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .list {
            padding: 0 32px;

            .li {
                min-height: 176px;
                padding: 16px;
                cursor: pointer;
                position: relative;
                padding-left: 288px;


                &:hover {
                    background-color: #F1F5FA;
                }

                .img_box {
                    width: 256px;
                    height: 144px;
                    position: absolute;
                    left: 16px;
                    top: 16px;

                    .el-image {
                        width: 100%;
                        height: 100%;
                    }
                }

                h1 {
                    color: #353842;
                    font-size: 16px;
                    margin-bottom: 16px;
                }

                .info {
                    padding-bottom: 16px;

                    p {
                        color: #A5A7AB;
                        font-size: 14px;
                        display: flex;
                        flex-wrap: wrap;

                        span {
                            color: inherit;
                            font-size: inherit;
                        }
                    }
                }

                .label_row {
                    display: flex;
                    flex-wrap: wrap;
                    position: absolute;
                    left: 288px;
                    bottom: 16px;

                    span {
                        min-width: 64px;
                        display: inline-block;
                        height: 20px;
                        line-height: 18px;
                        border: 1px solid #D9D9D9;
                        color: #A3A5A9;
                        padding: 0 5px;
                        margin-right: 8px;
                        text-align: center;
                        font-size: 12px;

                        &.ing {
                            color: #FAAD14;
                            border-color: #FAAD14;
                        }
                    }
                }
            }
        }

        .page_box {
            padding: 32px 22px;
            text-align: left;
        }
    }
}
</style>